import {
  BaseFooter,
  Footer,
  PageType,
  useTrackImpressionEventByIntersection,
} from "@product/scmp-sdk";
import { useAtomValue } from "jotai";
import { memo, useCallback } from "react";
import type { FunctionComponent } from "react";

import { Portal } from "scmp-app/components/common/portal";
import { useNewsletterWidget } from "scmp-app/components/newsletter/newsletter-widget-container/hooks";
import { sendGA4Tracking } from "scmp-app/components/tracking/google-analytics-4/apis";
import type { Event as Ga4Event } from "scmp-app/components/tracking/google-analytics-4/types";
import { useCurrentArticleIds } from "scmp-app/lib/current-item";
import { rosettaAtom } from "scmp-app/lib/rosetta";
import { useCurrentPageType } from "scmp-app/lib/router/hooks";
import { FooterElementID } from "scmp-app/pages/_app/consts";

import {
  DynamicHomeFooter,
  DynamicPlusFooter,
  DynamicPostiesFooter,
  DynamicPostMagazineFooter,
  DynamicStyleFooter,
} from "./dynamics";
import { Container } from "./styles";

export enum FooterVariant {
  Home = "home",
  MagazinesStyle = "magazines-style",
  PlusWithAppLinkRow = "plus-with-app-link-row",
  PlusWithoutAppLinkRow = "plus-without-app-link-row",
  PlusWithoutAppLinkRowAndFixed = "plus-without-app-link-row-and-fixed",
  PostMagazine = "post-magazine",
  Posties = "posties",
  Section = "section",
  Topic = "topic",
}

export type Props = {
  className?: string;
  hasPrintEditionFooter?: boolean;
  variant?: `${FooterVariant}`;
};

const AppFooter_: FunctionComponent<Props> = ({ className, hasPrintEditionFooter, variant }) => {
  const { dataMatrix } = useNewsletterWidget({
    contentType: "footer",
    eventCategory: "Newsletter (Footer - Inline)",
    section: "",
  });

  const asyncRosettaState = useAtomValue(rosettaAtom);
  const isScmpSubscriber = asyncRosettaState?.result?.user?.isSCMPSubscriber ?? false;
  const subscriberPhase = asyncRosettaState?.result?.tracking?.ga4.subscriberPhase;
  const currentPageType = useCurrentPageType();
  const { currentArticleId } = useCurrentArticleIds();

  const subscribeButtonImpressionGA4Event = useCallback<() => Ga4Event>(
    () => ({
      action: "imp",
      category: "subs",
      customized_parameters: {
        call_to_action: "subscribe",
        subscriber_phase: subscriberPhase,
        trigger_point: (() => {
          switch (currentPageType) {
            case PageType.Article:
              return "article footer";
            case PageType.Section:
              return "section footer";
            default:
              return "footer";
          }
        })(),
        ...(currentArticleId ? { article_id: currentArticleId } : {}),
      },
      subcategory: "entry_point",
    }),
    [currentPageType, subscriberPhase, currentArticleId],
  );
  const { captureTrackImpressionEventTargetElement: captureSubscribeButtonImpressionEvent } =
    useTrackImpressionEventByIntersection({
      ga4TrackingHandler: sendGA4Tracking,
      getGa4Event: subscribeButtonImpressionGA4Event,
      options: { isSendGA4Tracking: true, shouldSendOnce: true },
    });

  const renderFooter = () => {
    switch (variant) {
      case FooterVariant.Posties:
        return <DynamicPostiesFooter />;
      case FooterVariant.MagazinesStyle:
        return <DynamicStyleFooter />;
      case FooterVariant.Home:
      case FooterVariant.Section:
      case FooterVariant.Topic:
        return <DynamicHomeFooter />;
      case FooterVariant.PlusWithAppLinkRow:
        return <DynamicPlusFooter withAppLinkRow />;
      case FooterVariant.PlusWithoutAppLinkRowAndFixed:
        return <DynamicPlusFooter withAppLinkRow={false} fixed />;
      case FooterVariant.PlusWithoutAppLinkRow:
        return <DynamicPlusFooter withAppLinkRow={false} />;
      case FooterVariant.PostMagazine:
        return <DynamicPostMagazineFooter />;
      default:
        return (
          <div ref={isScmpSubscriber ? null : captureSubscribeButtonImpressionEvent}>
            <BaseFooter
              dataMatrix={dataMatrix}
              newsletterItems={[]}
              queryParametersForSocialButtons={queryParametersForSocialButtons}
            />
          </div>
        );
    }
  };

  const queryParametersForSocialButtons = {};
  return (
    <Portal selector={`#${FooterElementID}`}>
      <Container $variant={variant} className={className}>
        {hasPrintEditionFooter ? (
          <Footer
            dataMatrix={dataMatrix}
            queryParametersForSocialButtons={queryParametersForSocialButtons}
          />
        ) : (
          renderFooter()
        )}
      </Container>
    </Portal>
  );
};

export const AppFooter = memo(AppFooter_);

AppFooter.displayName = "AppFooter";
